/* Agrega estos estilos a HeaderStyles.css */

.hamburger-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.hamburger-line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
  transition: all 0.3s ease;
}

.hamburger-line.open:nth-child(1) {
  transform: rotate(45deg) translateY(6px);
}

.hamburger-line.open:nth-child(2) {
  opacity: 0;
}

.hamburger-line.open:nth-child(3) {
  transform: rotate(-45deg) translateY(-6px);
}

@media (max-width: 767px) {
  .hamburger-button {
    display: block;
  }
}
