/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Aplicar el fondo a todo el body */
body {
  @apply bg-cover bg-center min-h-screen;
  background-image: url('../public/assets/background2.jpg');
  background-color: rgba(0, 0, 0, 0.7); /* Overlay oscuro */
  background-blend-mode: overlay; /* Mezcla la imagen con el color de fondo */
}

/* Asegurar que el fondo también se aplique a un contenedor específico si es necesario */
.background-image {
  @apply bg-cover bg-center;
  background-image: url('../src/assets/fondoo.jpg');
  background-color: rgba(0, 0, 0, 0.7); /* Overlay oscuro */
  background-blend-mode: overlay; /* Mezcla la imagen con el color de fondo */
}

.bg-white {
  background-color: white;
}

/* Media queries para diferentes resoluciones de pantalla */

/* Asegurar que la imagen se ajuste bien en diferentes orientaciones */
body, .background-image {
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* Mantener el fondo fijo al hacer scroll */
}
