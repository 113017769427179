/* src/components/Layout.css */
.fixed {
    position: fixed;
}
.bottom-8 {
    bottom: 2rem;
}
.right-8 {
    right: 2rem;
}
.z-50 {
    z-index: 50;
}
.bg-boton {
    background-color: #000000; /* Ajusta al color que prefieras */
}
.hover\:bg-boton-dark:hover {
    background-color: #210B61; /* Ajusta al color de hover que prefieras */
}
.p-2 {
    padding: 0.8rem;
}
.rounded-full {
    border-radius: 9999px;
}
.shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.transition {
    transition: all 0.3s ease;
}
.duration-300 {
    transition-duration: 300ms;
}
